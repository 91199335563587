import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PostsList from './posts/PostsList';
import PostDetails from './posts/PostDetails';
import LoginPage from './pages/auth/login/LoginPage';
import RegisterPage from './pages/auth/register/RegisterPage';
import ProtectedRoute from './auth/ProtectedRoute';
import NotFound from './auth/NotFound';
import { AuthProvider } from './auth/AuthContext';
import UserDogs from './dogs/UserDogs';
import DogsAccount from './dogs/DogsAccount';
import HomePage from './pages/homepage/HomePage';
import Layout from './layouts/Layout';
import useAuthRedirect from './hooks/UseAuthRedirect';


function App() {
  

  return (
    <Router>
      <AuthProvider>
      <Layout>
      <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/home" element={<ProtectedRoute><HomePage></HomePage></ProtectedRoute>} />
            <Route
              path="/posts"
              element={
                <ProtectedRoute>
                  <PostsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/posts/:postId"
              element={
                <ProtectedRoute>
                  <PostDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <DogsAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dogs/:user_id"
              element={
                <ProtectedRoute>
                  <UserDogs />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
          </Routes>
        </Layout>
      </AuthProvider>
    </Router>
  );
}

export default App;
