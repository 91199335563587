import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';

import logo from '../../../assets/icons/nafni-logo-4.svg';
import carouselImage from '../../../assets/images/login.svg';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState('');


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await login(username, password);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleBack = () => {
    navigate('/register');
  };

  return (
    <div className='auth-content'>
      <div className='auth-left'>
        <div className='logo'>
          <img src={logo}></img>
        </div>
        <div className='login'>
          <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <Alert severity="error">{error}</Alert>}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                sx={{ mt: 1 }}
              >
                Register
              </Button>
            </Box>
          </Box>
        </Container>
        </div>

      </div>
      <div className='auth-right'>
        <div className='auth-carousel-container'>
            <img src={carouselImage}></img>
        </div>
      </div>
    </div>
     
  );
}

export default LoginPage;
