import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navigation/Navbar';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import './Layout.scss'; // Import SCSS for layout styling

const Layout = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
  
    const navRoutes = ['/home', '/settings', '/analytics'];
    const showNavbar = isLoggedIn && navRoutes.includes(location.pathname);

    useEffect(() => {
        if (isLoggedIn === null) return; // Waiting for authentication status
    
        // Redirect based on authentication status
        const path = location.pathname;
        if (isLoggedIn && (path === '/login' || path === '/register')) {
          navigate('/home');
        } else if (!isLoggedIn && path !== '/login' && path !== '/register') {
          navigate('/login');
        }
      }, [isLoggedIn, navigate]);
  
  return (
    <div className="layout">
      <div className="content">
      {showNavbar ? (
            <>
              <Navbar />
              <div className="main-content">
                {children} {/* This will render the main content passed as children */}
              </div>
            </>
          ) : (
            children
          )}
      </div>
    </div>
  );
};


  

export default Layout;

