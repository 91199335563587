import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
    const firstName = localStorage.getItem('first_name');
    setFirstName(firstName ?? 'empty')
  }, []);

  const login = async (username, password) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
  
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/auth/token/`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      if (response.status !== 200) {
        throw new Error('Login failed');
      }
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('user_image', response.data.image);
      localStorage.setItem('first_name', response.data.first_name);
      setFirstName(response.data.first_name ?? 'empty');
      navigate('/home');
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.status === 401) {
        throw new Error('Username or password is incorrect, please try again.');
      } else {
        throw new Error('An unexpected error occurred. Please try again later.');
      }
    }
  };
  

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_image');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, firstName }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);