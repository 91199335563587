import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext';
import '../../App.scss';
// import TinderCard from 'react-tinder-card';
// import { Card, CardWrapper } from 'react-swipeable-cards';
// Import necessary modules and assets
import { CardSwiper } from 'react-card-swiper';
// import bubbleShooter from '@/assets/images/bubble-shooter.png';
// ... (other imports)

import likeIcon from '../../assets/icons/like.svg';
import dislikeIcon from '../../assets/icons/dislike.svg';
import genderFemale from '../../assets/icons/gender-female.svg';
import genderMale from '../../assets/icons/gender-male.svg';
import genderNeutral from '../../assets/icons/gender-neutral.svg';
import cardMale from '../../assets/images/card-male.svg';
import cardMale2 from '../../assets/images/card-male-2.svg';
import cardFemale from '../../assets/images/card-female.svg';
import cardFemale2 from '../../assets/images/card-female-2.svg';
import cardNeutral from '../../assets/images/card-neutral.svg';
import cardNeutral2 from '../../assets/images/card-neutral-2.svg';


// Define your card data
const mockData = [
  { id: '18552078', meta: { apk: 'some-apk-a.apk' }, src: cardMale, content: <div className='card-details'><div className='name-and-sex'><h2>Sefán</h2><div><img src={genderMale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '28552078', meta: { apk: 'some-apk-a.apk' }, src: cardMale2, content: <div className='card-details'><div className='name-and-sex'><h2>Hinrik</h2><div><img src={genderMale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '38552078', meta: { apk: 'some-apk-a.apk' }, src: cardFemale, content: <div className='card-details'><div className='name-and-sex'><h2>Aðalheiður</h2><div><img src={genderFemale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '48552078', meta: { apk: 'some-apk-a.apk' }, src: cardFemale2, content: <div className='card-details'><div className='name-and-sex'><h2>Harpa</h2><div><img src={genderFemale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '58552078', meta: { apk: 'some-apk-a.apk' }, src: cardNeutral, content: <div className='card-details'><div className='name-and-sex'><h2>Blær</h2><div><img src={genderNeutral}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '68552078', meta: { apk: 'some-apk-a.apk' }, src: cardFemale, content: <div className='card-details'><div className='name-and-sex'><h2>Sveinbjörg</h2><div><img src={genderFemale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '78552078', meta: { apk: 'some-apk-a.apk' }, src: cardMale, content: <div className='card-details'><div className='name-and-sex'><h2>Hreinn</h2><div><img src={genderMale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '88552078', meta: { apk: 'some-apk-a.apk' }, src: cardFemale2, content: <div className='card-details'><div className='name-and-sex'><h2>Hugrún</h2><div><img src={genderFemale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },
  { id: '98552078', meta: { apk: 'some-apk-a.apk' }, src: cardMale2, content: <div className='card-details'><div className='name-and-sex'><h2>Þórhallur</h2><div><img src={genderMale}/></div></div><div className='name-description'>úr grísku: sigursveigur,blómsveigur, kóróna. 26/12. Stefán frumvottur (Post. 6,8).</div></div> },

  // Add other card data objects
];

// Implement your component using SwipeCard
function HomePage() {
    const [swipeData, setSwipeData] = useState([]);
    const swiperRef = useRef(null);

    const handleNextButtonClick = () => {
    console.log('Button clicked'); 
      // Check if swiperRef.current exists and has a next() method
      if (swiperRef.current && swiperRef.current.next) {
        swiperRef.current.next(); // Trigger next card display
      }
    };

  // Define event handlers
  const handleDismiss = (el, meta, id, action, operation) => {
    console.log({ el, meta, id, action, operation }); // Handle event data
    setSwipeData((prevSwipeData) => [
        ...prevSwipeData,
        { id, action },
        ]);
  };

  const handleFinish = (status) => {
    console.log(status); // 'finished'
  };

  const handleEnter = (el, meta, id) => {
    console.log(el, meta, id);
  };

  // Render SwipeCard component with desired props
  return (
    <div className='home-page'>
        <div className='cards-container'>
            <CardSwiper
                data={mockData}
                ref={swiperRef}
                onEnter={handleEnter}
                onFinish={handleFinish}
                onDismiss={handleDismiss}
                dislikeButton={<div className='card-buttons dislike'><img src={dislikeIcon}/></div>}
                likeButton={<div className='card-buttons like'><img src={likeIcon}/></div>}
                withActionButtons
                withRibbons
                likeRibbonText="Líkar"
                dislikeRibbonText="Líkar ekki"
                // ribbonColors={{ bgLike: '#41C2B9', bgDislike: '#595A84', textColor: 'white' }}
                emptyState={<div>empty</div>} // Replace with your custom empty state
            />
        </div>
    </div>
  );
}

export default HomePage