// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
        h2: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
        body1: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
      },
      palette: {
        primary: {
          main: '#5E5FEF' // Setting the primary color
        },
        text: {
            primary: '#595A84', // Custom text color
          },
      }
    });
export default theme;