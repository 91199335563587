import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';
import logo from '../../../assets/icons/nafni-logo-4.svg';
import carouselImage from '../../../assets/images/login.svg';


function RegisterPage() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    image: null,
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { username, password, first_name, last_name } = formData;

    // Check if all fields are filled
    if (!username || !password || !first_name || !last_name) {
      setError('All fields are required.');
      return false;
    }

    // Check password strength
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password must be at least 8 characters long and contain both letters and numbers.');
      return false;
    }

    setError('');
    return true;
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      await axios.post(`${import.meta.env.VITE_API_URL}/auth/`, formData);
      navigate('/login');
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
    }
  };

  const handleBack = () => {
    navigate('/login');
  };

  async function base64ConversionForImages(e) {
    if (e.target.files[0]) {
      getBase64(e.target.files[0]);
    }
  }

  function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFormData(prevFormData => ({
        ...prevFormData,
        image: reader.result
      }));
    };
    reader.onerror = function (error) {
      console.log('Error', error);
    }
  }

  return (
    <div className='auth-content'>
      <div className='auth-left'>
        <div className='logo'>
          <img src={logo} alt="Logo" />
        </div>
        <div className='login'>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Register
              </Typography>
              <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  autoComplete="fname"
                  autoFocus
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="lname"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={formData.username}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {error && <Alert severity="error">{error}</Alert>}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Register
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={handleBack}
                  sx={{ mt: 1 }}
                >
                  Back to Login
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <div className='auth-right'>
        <div className='auth-carousel-container'>
          <img src={carouselImage} alt="Carousel" />
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
