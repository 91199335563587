import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './App.scss'
import './styles/index.scss'
import CustomThemeProvider from './CustomThemeProvider'; // Import the custom theme provider

ReactDOM.createRoot(document.getElementById('root')).render(
    <CustomThemeProvider>
        <App />
    </CustomThemeProvider>
)
