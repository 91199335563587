
import React, { useState, useEffect, useRef } from 'react';
import './Navbar.scss';
import { useAuth } from '../../auth/AuthContext';


import homeIcon from '../../assets/icons/home.svg';
import homeIconActive from '../../assets/icons/home-active.svg';
import logo from '../../assets/icons/nafni-logo-4.svg';
import nafniIcon from '../../assets/icons/nafni-icon.svg';
import nafniIconActive from '../../assets/icons/nafni-icon-active.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import settingsIconActive from '../../assets/icons/settings-active.svg';
import analyticsIcon from '../../assets/icons/analytics.svg';
import analyticsIconActive from '../../assets/icons/analytics-active.svg';
import expandIcon from '../../assets/icons/expand.svg';
import collapseIcon from '../../assets/icons/collapse.svg';
import dropdownButtonArrows from '../../assets/icons/dropdown-arrows.svg';

import closeIcon from '../../assets/icons/close.svg';

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { logout, firstName } = useAuth();

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };
 
  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={`navbar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="navbar-header">
        <div className="navbar-logo">
          <img src={logo} alt="Nafni Logo" />
          {isExpanded && <span>Nafni</span>}
        </div>
        <button className="navbar-toggle" onClick={toggleNavbar}>
          {isExpanded ? <img src={collapseIcon} /> : <img src={expandIcon} />}
        </button>
      </div>
      <ul className="navbar-menu">
        <li className={`${location.pathname === '/home' ? 'active' : ''}`}><img src={`${location.pathname === '/home' ? nafniIconActive : nafniIcon}`} /> {isExpanded && <span>Stokkur</span>}</li>
        <li className={`${location.pathname === '/matches' ? 'active' : ''}`}><img src={`${location.pathname === '/matches' ? analyticsIconActive : analyticsIcon}`} /> {isExpanded && <span>Niðurstöður</span>}</li>
        <li className={`${location.pathname === '/settings' ? 'active' : ''}`}><img src={`${location.pathname === '/settings' ? settingsIconActive : settingsIcon}`} /> {isExpanded && <span>Stillingar</span>}</li>

      </ul>
      <div className="navbar-footer">
        <div className='profile-dropdown' ref={dropdownRef} onClick={toggleDropdown}>
          <div className='profile-circle'>
            <h3>H</h3>
          </div>
          {isExpanded && <div className="user-info">
            <div className='user-name'>{firstName}</div>
            <div className='user-email'>harpahreins96@gmail.com</div>
          </div>}
          {isExpanded && <img src={dropdownButtonArrows}></img>}
          
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <button onClick={handleLogout}><img src={homeIcon}/><div>Logout</div></button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
